import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import './Easily.css';

export default function Easily() {

    return (
        <div className='easily-container'>
            <div className='easily-container-inner'>
                <Parallax 
                    rotate={[0,720]}
                    className='easily-emoji-parallax'>
                    <span role='img' aria-label='smiley'><i className="fa-regular fa-infinity" style={{color: "#e7e7e3"}}></i></span>
                    <div className='easily-icon icon-1'><i className='fasr fa-qrcode' /></div>
                    <div className='easily-icon icon-2'><i className='fasr fa-qrcode' /></div>
                    <div className='easily-icon icon-3'><i className='fasr fa-qrcode' /></div>
                    <div className='easily-icon icon-4'><i className='fasr fa-qrcode' /></div>
                </Parallax>
                <div className='easily-words'>
                    <p>Possibilities</p>
                    <p>
                        QRs as unique as your brand. Through revolutionary features and capabilities, your QR codes become boundless.
                    </p>
                </div>
            </div>
        </div>
    )
}