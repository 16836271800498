import React, { useEffect } from 'react';
import LogoLight from '../../Images/LiquidQR-light.png';
import './Footer.css';

export default function Footer() {

    useEffect(() => {
        const bar = document.querySelector('.footer-container');
        if (window.location.pathname === '/pricing') {
            bar.classList.add('pricing');
        } else {
            bar.classList.remove('pricing');
        }
    })

    return (
        <div className='footer-container'>
            <div className='footer-top'>
                <div className='footer-first'>
                    <img src={LogoLight} className='footer-logo'/>
                    LiquidQR
                </div>
                <div className='footer-second'>
                    <div className='footer-title'>Contact Us<span className='footer-title-underline1'></span></div>
                    <a href='tel:+8664931311'>866-493-1311</a>
                    <a href='mailto:ask@liquidqr.com'>ask@liquidqr.com</a>
                    <p style={{color: '#666', marginBottom: '0'}}>Proudly based in Minnesota, USA</p>
                </div>
                <div className='footer-third'>
                    <div className='footer-title'>Menu<span className='footer-title-underline2'></span></div>
                    <div className='footer-third-inner'>
                        <div className='footer-third-column'>
                            <a href='/pricing'>Pricing</a>
                            <a href='https://app.liquidqr.com/login'>Log In</a>
                            <a href='/store'>Store</a>
                        </div>
                        <div className='footer-third-column'>
                            <a href="https://www.iubenda.com/terms-and-conditions/96137534" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Terms and Conditions ">Terms and Conditions</a>
                            <a href="https://www.iubenda.com/privacy-policy/96137534" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
                            <a href="https://www.iubenda.com/privacy-policy/96137534/cookie-policy" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                © 2024 All Rights Reserved, LiquidQR
            </div>
        </div>
    )
}