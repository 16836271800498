import React, { useState } from 'react';
import './ScanLike.css';

export default function ScanLike() {

    const [ companies ] = useState([
        { name: 'Promotions', icon: "fa-regular fa-megaphone"},
        { name: 'Menu', icon: "fa-regular fa-plate-utensils"},
        { name: 'Links', icon: "fa-regular fa-link"},
        { name: 'Packaging', icon: "fa-regular fa-box-open-full"},
        { name: 'Appointments', icon: "fa-regular fa-calendar-check"},
        { name: 'Signage', icon: "fa-regular fa-sign-hanging"},
        { name: 'PDFs', icon: "fa-regular fa-file-pdf"},
        { name: 'Websites', icon: "fa-regular fa-browser"},
        { name: 'Deals', icon: "fa-regular fa-badge-percent"},
        { name: 'Location', icon: "fa-regular fa-location-dot"},
        { name: 'Message', icon: "fa-regular fa-messages"},
        { name: 'Discounts', icon: "fa-regular fa-tag"},
        { name: 'Fundraisers', icon: "fa-regular fa-lightbulb-dollar"},
        { name: 'Advertisements', icon: "fa-regular fa-rectangle-ad"},
        { name: 'Event', icon: "fa-regular fa-calendar-days"},
        { name: 'Displays', icon: "fa-regular fa-display"}
    ])

    return (
        <div className='scan-like-container'>
            <div className='scan-like'>
                <div className='scan-like-words'>Unleash your</div>
                <div className='scan-like-companies-wrapper'>
                    <div className='scan-like-companies'>
                        {companies?.map((c, index) => (
                            <div className='scan-like-company' key={index}>
                                <i className={c.icon}></i>
                                <span>{c.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className='scan-like-companies'>
                        {companies?.map((c, index) => (
                            <div className='scan-like-company' key={index}>
                                <i className={c.icon}></i>
                                <span>{c.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}