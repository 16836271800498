import React, { useState } from 'react';
import Code from '../../Images/LQRLogo4.png';
import './ScanLikeLarge.css';

export default function ScanLikeLarge() {

    const [ companies ] = useState([
        'Restaurants', 'Real Estate', 'Stores', 'Professionals', 'Marketing', 'Business', 'Hospitality', 'Retail', 'Insurance', 'Schools', 'Print', 'Healthcare'
    ]);

    const handleLabels = (e) => {
        const label = document.querySelector(`[for="${e.target.id}"]`);
        if (e.target.value.length > 0) {
            label.classList.add('filled');
        } else {
            label.classList.remove('filled');
        };
    };

    return (
        <div className='scan-large-container'>
            <p>Perfect for...</p>
            <div className='scan-large-companies-wrapper'>
                    <div className='scan-large-companies'>
                        {companies?.map((c, index) => (
                            <div className='scan-large-company' key={index} style={{backgroundImage: `url(${Code})`}}>
                                <span>{c}</span>
                            </div>
                        ))}
                    </div>
                    <div className='scan-large-companies'>
                        {companies?.map((c, index) => (
                            <div className='scan-large-company' key={index} style={{backgroundImage: `url(${Code})`}}>
                                <span>{c}</span>
                            </div>
                        ))}
                    </div>
                </div>
            <p>Save, track, manage, organize, design, and update your QRs all from one convenient dashboard</p>
            <div className='scan-large-input-wrapper'>
                <input type='email' id='scan-large-email' className='scan-large-input' onBlur={handleLabels} required />
                <label htmlFor='scan-large-email' className='floating-label'>Email</label>
                <button className='scan-large-btn' onClick={() => window.open(`https://app.liquidqr.com/signup?email=${document.getElementById('scan-large-email').value}`)}>Sign up</button>
            </div>
        </div>
    )
}