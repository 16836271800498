import React from 'react';
import image from '../../Images/generator.png';
import './QRBuilder.css';

export default function QRBuilder() {


    const handleClick = () => {
        window.open('https://generator.liquidqr.com/', '_blank');
    };

    return (
        <div className='builder-container'>
            <div className='builder-container-title'>Try it out!</div>
            <div className='builder-container-inner' onClick={handleClick}>
                <img src={image} width='100%' height='auto' />
                <button className='builder-container-btn'>Generator</button>
            </div>
        </div>
    );
};