import React from 'react';
import Ripple2 from '../Ripple2';
import './ReadySetScan.css';

export default function ReadySetScan() {

    const handleLabels = (e) => {
        const label = document.querySelector(`[for="${e.target.id}"]`);
        if (e.target.value.length > 0) {
            label.classList.add('filled');
        } else {
            label.classList.remove('filled');
        };
    };

    return (
        <div className='ready-set-container'>
            <Ripple2 />
            <p>Get Creative</p>
            <p>Design, link, and share your custom QRs in under a minute. For free.</p>
            <div className='ready-set-input-wrapper'>
                <input type='email' id='ready-set-email' className='ready-set-input' onBlur={handleLabels} required />
                <label htmlFor='ready-set-email' className='floating-label'>Email</label>
                <button className='ready-set-btn' onClick={() => window.open(`https://app.liquidqr.com/signup?email=${document.getElementById('ready-set-email').value}`)}>Sign up</button>
            </div>
        </div>
    )
}