import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AllTheExtras.css';

export default function AllTheExtras() {

    const navigate = useNavigate();

    return (
        <div className='ate-container'>
            <div className='ate-container-inner'>
                <div className='ate-animate-container'>
                    <i className='fak fa-liquidqr fa-bounce' style={{fontSize: '10.5rem', color: '#e7e7e3', '--fa-animation-duration': '3s', '--fa-animation-timing': 'ease-in'}} />
                </div>
                <div className='ate-words'>
                    <p>Loaded with features</p>
                    <p>Discover the most advanced QR solution available.  Your dashboard comes equipt with:</p>
                    <div className='ate-tags'>
                        <span>Campaigns</span>
                        <span>Tags</span>
                        <span>Teams</span>
                        <span>Branding</span>
                        <span>Microsites</span>
                        <span>PDF Menus</span>
                        <span>Password Protected QRs</span>
                        <span>Timed Releases</span>
                    </div>
                    <button className='ate-btn' onClick={() => navigate('/pricing')}>Compare plans</button>
                </div>
            </div>
        </div>
    )
}