import React from 'react';
import TopBar from '../../components/LandingPage/TopBar';
import QR from '../../components/Images/LiquidQR-light.png';
import QRbg from '../../components/Images/qr-background1.png';
import StoreItems from '../../components/StoreItems';
import ScanMe from '../../components/Images/ScanMe.png';
import Menu from '../../components/Images/Menu.png';
import Normal from '../../components/Images/Normal.png';
import './Store.css';
import Footer from '../../components/LandingPage/Footer';

export default function Store() {

    const handleShowNow = () => {
        window.scrollTo(0, 500);
    }

    // const handleSticker = (e) => {
    //     console.log('hit')
    //     const id = e.target.id;
    //     if (id === 'menu-permanent') {
    //         window.location.href = 'https://buy.stripe.com/3csdTY9SkeH12VqcMM';
    //     } else if (id === 'scan-permanent') {
    //         window.location.href = 'https://buy.stripe.com/cN2g263tW56r67C8wx';
    //     } else if (id === 'normal-permanent') {
    //         window.location.href = 'https://buy.stripe.com/4gwaHMaWo42n1RmcMO';
    //     } else if (id === 'menu-removable') {
    //         window.location.href = 'https://buy.stripe.com/4gw4joc0s6av67C6or';
    //     } else if (id === 'scan-removable') {
    //         window.location.href = 'https://buy.stripe.com/fZe8zE4y042n8fK6os';
    //     } else if (id === 'normal-removable') {
    //         window.location.href = 'https://buy.stripe.com/7sI2bgd4wdCX7bG5kp';
    //     }
    // }

    return (
        <div className='store-wrapper'>
            <div className='store-container'>
                <TopBar />
                <div className='store-top'>
                    <div className='store-top-left'>
                        <div className='store-top-title'>Build the Perfect QR</div>
                        <div className='store-top-info'>
                            Creating a QR with your LiquidQR account is fast and easy,
                            but it doesn't stop there. QR stickers are available for you
                            to place wherever and whenever you want.
                        </div>
                        <div className='store-top-btn-container'>
                            <button onClick={() => window.location.href='https://app.liquidqr.com/login'} className='store-top-btn'>Start Creating</button>
                            <button className='store-top-btn' onClick={handleShowNow}>Shop Now</button>
                        </div>
                    </div>
                    <div className='store-top-right'>
                        <div className='store-top-img'>
                            <img src={QR} alt='LiquidQR' />
                        </div>
                    </div>
                </div>
                <div className='store-middle' style={{backgroundImage: `url(${QRbg})`}}></div>
                <StoreItems />
                {/* <div className='store-bottom'>
                    <div className='store-bottom-stickers'>
                        <div className='store-bottom-sticker' id='menu-permanent' onClick={e => handleSticker(e)}>
                            <img src={Menu} alt='QR Scan' />
                            <div className='store-bottom-info'>"Menu" QR Stickers 10-Pack &#40;Permanent&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                        <div className='store-bottom-sticker' id='scan-permanent' onClick={e => handleSticker(e)}>
                            <img src={ScanMe} alt='QR Scan' />
                            <div className='store-bottom-info'>"Scan Me" QR Stickers 10-Pack &#40;Permanent&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                        <div className='store-bottom-sticker' id='normal-permanent' onClick={e => handleSticker(e)}>
                            <img src={Normal} alt='QR Scan' />
                            <div className='store-bottom-info'>QR Stickers 10-Pack &#40;Permanent&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                        <div className='store-bottom-sticker' id='menu-removable' onClick={e => handleSticker(e)}>
                            <img src={Menu} alt='QR Scan' />
                            <div className='store-bottom-info'>"Menu" QR Stickers 10-Pack &#40;Removable&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                        <div className='store-bottom-sticker' id='scan-removable' onClick={e => handleSticker(e)}>
                            <img src={ScanMe} alt='QR Scan' />
                            <div className='store-bottom-info'>"Scan Me" QR Stickers 10-Pack &#40;Removable&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                        <div className='store-bottom-sticker' id='normal-removable' onClick={e => handleSticker(e)}>
                            <img src={Normal} alt='QR Scan' />
                            <div className='store-bottom-info'>QR Stickers 10-Pack &#40;Removable&#41;</div>
                            <div className='store-bottom-price'>$6.95</div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
};