import React from 'react';
import './LAnalytics.css';

export default function LAnalytics() {
    
    return (
        <div className='lanalytics-container'>
            <div className='lanalytics-container-inner'>
                <div className='lanalytics-words'>
                    <p>Analytics</p>
                    <p>Track campaigns. Report on QR popularity, scan times, frequency, and more. Compare success rates between QR codes. A/B test your QRs. Watch your charts and graphs update in real-time.</p>
                </div>
                <div className='lanalytics-animate'>
                    <div className='lanalytics-animate-inner'>
                        <span className='animate1' />
                        <span className='animate2' />
                        <span className='animate3' />
                        <span className='animate4' />
                        <span className='animate5' />
                    </div>
                </div>
            </div>
        </div>
    )
}