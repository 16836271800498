import React, { useEffect } from 'react';
import './StoreItems.css';

const MyStoreComponent = () => {
  useEffect(() => {
    // Function to dynamically load the Ecwid script
    const loadEcwidScript = (src, callback) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true; // Load asynchronously
      script.onload = callback; // Call callback function once the script is loaded
      document.body.appendChild(script);
    };

    // Inline script content as a callback function
    const onEcwidScriptLoad = () => {
      if (window.xProductBrowser) {
        window.xProductBrowser("categoriesPerRow=3", "views=grid(20,3) list(60) table(60)", "categoryView=grid", "searchView=list", "id=my-store-100051864");
      } else {
        console.error('xProductBrowser function is not defined.');
      }
    };

    // Load the main Ecwid script and set onEcwidScriptLoad as the callback to execute afterward
    loadEcwidScript("https://app.ecwid.com/script.js?100051864&data_platform=code&data_date=2024-03-04", onEcwidScriptLoad);

    // No need to explicitly remove scripts in cleanup because they don't interfere with React's virtual DOM
  }, []);

  return (
    <div className='storeContainer'>
      <div id="my-store-100051864"></div>
    </div>
  );
};

export default MyStoreComponent;
