import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../../components/LandingPage/TopBar';
import Ripple from '../../components/LandingPage/Ripple';
import ScanLike from '../../components/LandingPage/ScanLike';
import Easily from '../../components/LandingPage/Easily';
import ReadySetScan from '../../components/LandingPage/ReadySetScan';
import LAnalytics from '../../components/LandingPage/LAnalytics';
import AllTheExtras from '../../components/LandingPage/AllTheExtras';
import ScanLikeLarge from '../../components/LandingPage/ScanLikeLarge';
import Footer from '../../components/LandingPage/Footer';
import LogoLight from '../../components/Images/LiquidQR-light.png';
import { ParallaxProvider } from 'react-scroll-parallax';
import FloatingStore from '../../components/LandingPage/FloatingStore';
// import Water from '../../components/Images/water2.png';
import QRBuilder from '../../components/LandingPage/QRBuilder';
import Color from '../../components/Images/color.png';
import './Landing.css';

const Word = () => {
    const [index, setIndex] = useState(0);
    const words = ['Management', 'Customization', 'Creation'];
  
    useEffect(() => {
      const FADE_INTERVAL_MS = 3000;
  
      const intervalId = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, FADE_INTERVAL_MS);
  
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, []); // Empty dependency array ensures this effect runs only once
  
    const currentWord = words[index];
  
    return <>{currentWord}</>;
  };

export default function Landing() {

    const navigate = useNavigate();

    
    return (
        <ParallaxProvider>
            <div className='landing-wrapper'>
                <div className='landing-container'>
                    <TopBar />
                    <div className='ripple-container'>
                        <Ripple />
                        <img src={LogoLight} className='ripple-img' />
                        <p>LiquidQR</p>
                        <div className='ripple-words-wrap'>
                            <Word /> 
                        </div>
                        <p>
                            QR codes at their full potential.<br/>
                            Instantly change what happens when your QR codes are scanned...
                            worldwide... in real-time.
                        </p>
                        <div className='ripple-btns'>
                            <button className='start-free-btn me-2' onClick={() => window.open('https://app.liquidqr.com/signup')}>Start for Free</button>
                            <button className='compare-plans-btn ms-2' onClick={() => navigate('/pricing')}>Compare Plans</button>
                        </div>
                    </div>
                    <div className='water-container' style={{background: `url(${Color})`}}>
                        <div className='water-container-top' />
                        <p>What will you QR?</p>
                        <div className='water-container-bottom' />
                    </div>
                    <ScanLike />
                    <Easily />
                    <ReadySetScan />
                    <LAnalytics />
                    <div className='water-container' style={{background: `url(${Color})`}}>
                        <div className='water-container-top' />
                        <p>Where will your QRs go today</p>
                        <div className='water-container-bottom' />
                    </div>
                    <AllTheExtras />
                    <QRBuilder />
                    <ScanLikeLarge />
                    <Footer />
                    <FloatingStore />
                </div>
            </div>
        </ParallaxProvider>
    );
}