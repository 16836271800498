import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './pages/Landing';
import Pricing from './pages/Pricing';
import Store from './pages/Store';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route exact path='/pricing' element={<Pricing />} />
        <Route exact path='/store' element={<Store />} />
      </Routes>
    </Router>
  );
}

export default App;
