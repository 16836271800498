import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoDark from '../../Images/LiquidQR-dark.png';
import LogoLight from '../../Images/LiquidQR-light.png';
import './TopBar.css';

export default function TopBar() {

    const navigate = useNavigate();
    const [isDark, setIsDark ] = useState(true);

    useEffect(() => {
        const bar = document.querySelector('.top-bar');
        const logo = document.querySelector('.top-bar-logo');
        const aTag = document.querySelector('.top-bar-btns a');
        const btn = document.querySelector('.top-bar-btns button');
        const store = document.querySelector('.floating-container');
        window.onscroll = (e) => {
            // console.log(e);
            onScroll();
        }
        window.ontouchmove = (e) => {
            // console.log(window.scrollY)
            onScroll();
        }
        
        const onScroll = (e) => {
            // e?.path[1]?.scrollY > 0 || e?.path[9]?.scrollY > 0
            if (window.scrollY > 0) {
                setIsDark(false);
                bar.classList.add('scrolled');
                logo.classList.add('scrolled');
                aTag.classList.add('scrolled');
                btn.classList.add('scrolled');
                store?.classList.add('scrolled');
            } else {
                setIsDark(true);
                bar.classList.remove('scrolled');
                logo.classList.remove('scrolled');
                aTag.classList.remove('scrolled');
                btn.classList.remove('scrolled');
                store?.classList.remove('scrolled');
            }

            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                store?.classList.add('hide');
            } else {
                store?.classList.remove('hide');
            };
        };

        // if (window.location.pathname === '/pricing') {
        //     bar.classList.add('scrolled');
        //     logo.classList.add('scrolled');
        //     aTag.classList.add('scrolled');
        //     btn.classList.add('scrolled');
        // } else {
        //     bar.classList.remove('scrolled');
        //     logo.classList.remove('scrolled');
        //     aTag.classList.remove('scrolled');
        //     btn.classList.remove('scrolled');
        // }

    },[])

    return (
        <div className='top-bar-container'>
            <div className='top-bar'>
                <div className='top-bar-logo' onClick={() => navigate('/')} style={{cursor: 'pointer'}}>
                    <img src={isDark ? LogoDark : LogoLight} className='top-bar-img' />
                    <div className='top-bar-desktop'>LiquidQR</div>
                </div>
                <div className='top-bar-btns'>
                    <a href='https://app.liquidqr.com/'>Dashboard</a>
                    <button onClick={() => window.open('https://app.liquidqr.com/signup')}>Sign up</button>
                </div>
            </div>
        </div>
    )
}