import React from 'react';
import './FloatingStore.css';

export default function FloatingStore() {

    return (
        <a className='floating-container' href='/store' title="Shop Stickers">
            <i className='fas fa-store' />
            <div className='floating-words'>Shop Stickers</div>
        </a>
    );
};