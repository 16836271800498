import React, { useEffect, useState } from 'react';
import Footer from '../../components/LandingPage/Footer';
import TopBar from '../../components/LandingPage/TopBar';
import './Pricing.css';

export default function Pricing() {

    const [ display, setDisplay ] = useState('basic');

    const handleChoice = (e) => {
        if (e.target.id === 'pricing-free') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=free');
        } else if (e.target.id === 'pricing-basic') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=basic');
        } else if (e.target.id === 'pricing-basic+') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=basic+');
        } else if (e.target.id === 'pricing-pro') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=pro');
        } else if (e.target.id === 'pricing-pro+') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=pro+');
        } else if (e.target.id === 'pricing-enterprise') {
            window.location.assign('https://app.liquidqr.com/upgrade?redirect=upgrade&tier=enterprise');
        }
    };

    return (
        <div className='pricing-wrapper'>
            <div className='pricing-container'>
                <TopBar />
                <div className='pricing-top'>
                    <div className='mt-5'>Plan Comparison</div>
                    <div className='pricing-mobile-btns'>
                        <button className={display === 'free' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('free')}>Free</button>
                        <button className={display === 'basic' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('basic')}>Basic</button>
                        <button className={display === 'basic+' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('basic+')}>Basic+</button>
                        <button className={display === 'pro' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('pro')}>Pro</button>
                        <button className={display === 'pro+' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('pro+')}>Pro+</button>
                        <button className={display === 'enterprise' ? 'pricing-mobile-btn selected' : 'pricing-mobile-btn'} onClick={() => setDisplay('enterprise')}>Enterprise</button>
                    </div>
                </div>
                <div className='pricing-information'>
                    <div className='pricing-info'>
                        <div>LQR(s)</div>
                        <div>Users</div>
                        <div>Campaigns</div>
                        <div>Support</div>
                        <div>Analytics</div>
                        <div>Price</div>
                    </div>
                    <div className='pricing-liquid-container'>
                        <div className='pricing-liquid-container-inner-desktop'>
                            <div className='pricing-liquid-1'>
                                <div className="pricing-liquid-bg">
                                    <div className='pricing-top-row'>
                                        Free
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>1</div>
                                        <div>1</div>
                                        <div>1</div>
                                        <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban' /></div>
                                        <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban'/></div>
                                        <div>Free</div>
                                        <button className='pricing-btn none' id='pricing-free' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops1">
                                    <div className="drop1"></div>
                                    <div className="drop2"></div>  
                                </div>
                            </div>
                            <div className='pricing-liquid-2'>
                                <div className="pricing-liquid-bg">
                                    <div className='pricing-top-row'>
                                        Basic
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>10</div>
                                        <div>1</div>
                                        <div>3</div>
                                        <div>Basic</div>
                                        <div>Basic</div>
                                        <div>$4.95<span>/month</span></div>
                                        <button className='pricing-btn none' id='pricing-basic' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops2">
                                    <div className="drop1"></div>
                                    <div className="drop3"></div>  
                                </div>
                            </div>
                            <div className='pricing-liquid-3'>
                                <div className="pricing-liquid-bg">
                                    <div className='pricing-top-row'>
                                        Basic+
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>25</div>
                                        <div>1</div>
                                        <div>3</div>
                                        <div>Basic</div>
                                        <div>Basic</div>
                                        <div>$9.95<span>/month</span></div>
                                        <button className='pricing-btn none' id='pricing-basic+' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops3">
                                    <div className="drop1"></div>
                                    <div className="drop4"></div>  
                                </div>
                            </div>
                            <div className='pricing-liquid-4'>
                                <div className="pricing-liquid-bg">
                                    <div className='pricing-top-row'>
                                        Pro
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>100</div>
                                        <div>3</div>
                                        <div>10</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>$39.95<span>/month</span></div>
                                        <button className='pricing-btn none' id='pricing-pro' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops4">
                                    <div className="drop1"></div>
                                    <div className="drop5"></div>  
                                </div>
                            </div>
                            <div className='pricing-liquid-5'>
                                <div className='pricing-liquid-bg'>
                                    <div className='pricing-top-row'>
                                        Pro+
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>300</div>
                                        <div>5</div>
                                        <div>25</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>$59.95<span>/month</span></div>
                                        <button className='pricing-btn none' id='pricing-pro+' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops5">
                                    <div className="drop1"></div>
                                    <div className="drop6"></div>  
                                </div>
                            </div>
                            <div className='pricing-liquid-6'>
                                <div className='pricing-liquid-bg'>
                                    <div className='pricing-top-row'>
                                        Enterprise
                                    </div>
                                    <div className='pricing-bottom-row'>
                                        <div>Custom</div>
                                        <div>Custom</div>
                                        <div>Custom</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>Custom</div>
                                        <button className='pricing-btn none' id='pricing-enterprise' onClick={handleChoice}>Choose</button>
                                    </div>
                                </div>
                                <div className="drops6">
                                    <div className="drop1"></div>
                                    <div className="drop7"></div>  
                                </div>
                            </div>
                        </div>
                        <div className='pricing-liquid-container-inner-mobile'>
                            {display === 'free' ?
                                <div className='pricing-liquid-1'>
                                    <div className="pricing-liquid-bg">
                                        <div className='pricing-top-row'>
                                            Free
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>1</div>
                                            <div>1</div>
                                            <div>1</div>
                                            <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban' /></div>
                                            <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban'/></div>
                                            <button className='pricing-btn none' id='pricing-free' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops1">
                                        <div className="drop1"></div>
                                        <div className="drop2"></div>  
                                    </div>
                                </div>
                            : display === 'basic' ?
                                <div className='pricing-liquid-2'>
                                    <div className="pricing-liquid-bg">
                                        <div className='pricing-top-row'>
                                            Basic
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>10</div>
                                            <div>1</div>
                                            <div>3</div>
                                            <div>Basic</div>
                                            <div>Basic</div>
                                            <button className='pricing-btn none' id='pricing-basic' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops2">
                                        <div className="drop1"></div>
                                        <div className="drop3"></div>  
                                    </div>
                                </div>
                            : display === 'basic+' ?
                                <div className='pricing-liquid-3'>
                                    <div className="pricing-liquid-bg">
                                        <div className='pricing-top-row'>
                                            Basic+
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>25</div>
                                            <div>1</div>
                                            <div>3</div>
                                            <div>Basic</div>
                                            <div>Basic</div>
                                            <button className='pricing-btn none' id='pricing-basic+' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops3">
                                        <div className="drop1"></div>
                                        <div className="drop4"></div>  
                                    </div>
                                </div>
                            : display === 'pro' ?
                                <div className='pricing-liquid-4'>
                                    <div className='pricing-liquid-bg'>
                                        <div className='pricing-top-row'>
                                            Pro
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>100</div>
                                            <div>3</div>
                                            <div>10</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <button className='pricing-btn none' id='pricing-pro' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops4">
                                        <div className="drop1"></div>
                                        <div className="drop5"></div>  
                                    </div>
                                </div>
                            : display === 'pro+' ?
                                <div className='pricing-liquid-5'>
                                    <div className='pricing-liquid-bg'>
                                        <div className='pricing-top-row'>
                                            Pro+
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>300</div>
                                            <div>5</div>
                                            <div>25</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <button className='pricing-btn none' id='pricing-pro+' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops5">
                                        <div className="drop1"></div>
                                        <div className="drop6"></div>  
                                    </div>
                                </div>
                            : display === 'enterprise' &&
                                <div className='pricing-liquid-6'>
                                    <div className='pricing-liquid-bg'>
                                        <div className='pricing-top-row'>
                                            Enterprise
                                        </div>
                                        <div className='pricing-bottom-row'>
                                            <div>Custom</div>
                                            <div>Custom</div>
                                            <div>Custom</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <button className='pricing-btn none' id='pricing-enterprise' onClick={handleChoice}>Choose</button>
                                        </div>
                                    </div>
                                    <div className="drops6">
                                        <div className="drop1"></div>
                                        <div className="drop7"></div>  
                                    </div>
                                </div>
                            }
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{position: 'absolute'}}>
                            <defs>
                                <filter id="liquid" >
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" type='matrix' values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="liquid" />
                                </filter> 
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}